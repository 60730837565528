import { Link } from 'gatsby';
import * as React from 'react';
import { Component } from 'react';

export default class MobileNav extends Component<any> {
  constructor(props: any) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.setState({ location: process.env.LOCATION });
  }

  toggle() {
    this.props.toggleSideMenu();
  }

  render() {
    const { isVisible } = this.props;

    const menu = (
      <div className="fx-mobile-nav">
        <div className={`fx-mobile-nav-container ${isVisible ? 'fx-mobile-nav-container--visible' : ''}`}>
          <div className="fx-mobile-nav-links">
            <div className="fx-mobile-nav-links--main fx-mobile-nav-links--home">
              <Link to="/">Home</Link>
              <span className="fx-icon fx-icon-dismiss-lg" onClick={this.toggle} />
            </div>
          </div>
          <div className="fx-mobile-nav-divider" />
          <div className="fx-mobile-nav-links">
            <Link to="/drop-your-bill">Drop Your Bill</Link>
                </div>
                <div className="fx-mobile-nav-divider" />
          <div className="fx-mobile-nav-links">
            <a href="http://www.findex.com.au" target="_blank">Findex Home</a>
          </div>
        </div>
        <div className="fx-mobile-nav-backdrop" onClick={this.toggle} />
      </div>
    );

    return isVisible ? menu : null;
  }
}
