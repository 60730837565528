import * as React from 'react';
import Helmet from 'react-helmet';
import FxHeader from './header';
import FxMobileNav from './mobile-nav';
// tslint:disable-next-line:no-var-requires
require('es6-promise').polyfill();

export default class FxLayout extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      toggleSideMenu: false
    };
    this.toggleSideMenu = this.toggleSideMenu.bind(this);
  }

  toggleSideMenu() {
    this.setState({ toggleSideMenu: !this.state.toggleSideMenu });
  }

  render() {
    return (
      <div className="fx-l">
        <Helmet>
          <meta name="google-site-verification" content="" />
          <html lang="en" />
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
          <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" />
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        </Helmet>
        <FxHeader toggleSideMenu={this.toggleSideMenu} />

        <FxMobileNav isVisible={this.state.toggleSideMenu} toggleSideMenu={this.toggleSideMenu} />
        <main className="fx-l-main">{this.props.children}</main>
      </div>
    );
  }
}
