import { Link } from 'gatsby';
import * as React from 'react';
import logo from '../../../assets/img/logo.svg';

export default class FxHeader extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.toggleSideMenu = this.toggleSideMenu.bind(this);
  }

  toggleSideMenu(e: any) {
    e.preventDefault();
    this.props.toggleSideMenu(e);
  }

  render() {
    return (
      <header className="fx-l-header">
        <div className="fx-l-header-logo">
          <Link to="/home">
            <img className="fx-nav-logo-image" src={logo} alt="Findex" />
          </Link>
        </div>
        <div className="fx-l-header-links">
          <div className="fx-l-header-links-mobile">
            <a
              href=""
              className="fx-l-header-links-link fx-navbar-link--toggle"
              onClick={this.toggleSideMenu}
            >
              <span className="fx-icon fx-icon-toggle-lg" />
            </a>
          </div>
          <div className="fx-l-header-links-desktop">
            <Link
              to="/home"
              className="fx-l-header-links-link"
              activeClassName="fx-l-header-links-link--active"
              partiallyActive={true}
            >
              Home
            </Link>
            <Link
              to="/drop-your-bill"
              className="fx-l-header-links-link"
              activeClassName="fx-l-header-links-link--active"
              partiallyActive={true}
            >
              Drop Your Bill
            </Link>
            <span className="fx-l-header-links-link">
              <a href="http://www.findex.com.au">Findex Home</a>
            </span>
          </div>
        </div>
      </header>
    );
  }
}
